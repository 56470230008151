<template>
  <div class='order_detailed_container' :class="{ 'paid': order.state === 'PDP' }">

    <!-- Resumen y botones -->
    <div class='order_detailed_container--initial_data_and_buttons'>
      <div class='order_detailed_container--initial_data_and_buttons--initial_data'>
        <div class='order_detailed_container--initial_data_and_buttons--initial_data--frame'>
          <div class='text-s-bold'>{{ order.code }}</div>
          <div class='text-s-bold'>{{ order.date }}</div>
        </div>
        <div class='order_detailed_container--initial_data_and_buttons--initial_data--frame'>
          <div class='text-s-regular'>{{ order.serviceType }}</div>
          <div class='text-s-bold'>{{ order.stateDescription }}</div>
        </div>
      </div>
      <div class='order_detailed_container--initial_data_and_buttons--tracking_buttons'>
        <div style='width: 163px'>
          <ButtonDefinitive
            variant='ghost'
            size='s'
            label='Rastrear envío'
            @click='sendToTracking'
          />
        </div>
        <div style='width: 185px'>
          <ButtonDefinitive
            variant='tertiary'
            size='s'
            :label='textCopyTracking'
            @click='copyTracking'
          >
            <template v-slot:icon>
              <Icon name='Copy-2' strokeColor='#FF6568' />
            </template>
          </ButtonDefinitive>
        </div>
      </div>
    </div>
    <!-- Pendiente de pago -->
    <div class='order_detailed_container--amount_to_paid' v-if="order.state === 'PDP'">
      <div class='text-m-regular'>Paga con el código de pago (CIP) y podrás acceder a las guías de tus paquetes.</div>
      <div class='order_detailed_container--amount_to_paid--card_payment_content'>
        <div class='cip-content'>
          <span class='text-s-regular'>Código de pago (CIP)</span>
          <span class='text-xl-bold'>{{ order.pagoEfectivoCip }}</span>
        </div>
        <div class='button-copy' @click='copyText'>
          <Icon />
          <span class='text-m color-primary'>{{ textCopyCIP }}</span>
        </div>
      </div>
      <div class='order_detailed_container--amount_to_paid--card_payment_content'>
        <span class='text-s-regular'>Monto a pagar</span> <br>
        <span class='text-xl-bold'>S/ {{ order.totalOrderFare.toFixed(2) }}</span>
      </div>
      <div class='order_detailed_container--amount_to_paid--card_payment_content'>
        <span class='text-s-regular'>Vencimiento</span> <br>
        <span class='text-m-bold'>
          {{ (new Date(order.pagoEfectvioExpirationDate)).toLocaleString('es', { weekday: "long", year: "numeric", month: "long", day: "numeric"}) }}
          <br>
          {{ (new Date(order.pagoEfectvioExpirationDate)).toLocaleTimeString("es", { hour: "2-digit", minute: "2-digit", hour12: true }) }}
        </span>
      </div>
    </div>
    <!-- Monto pagado -->
    <div class='order_detailed_container--amount_paid' v-else>
      <div class='text-m-regular'>Pagaste: </div>
      <div class='text-m-bold'>S/ {{ order.totalFare.toFixed(2) }}</div>
    </div>
    <!-- ¿Quién envía? -->
    <div class='order_detailed_container--specific_information'>
      <div class='text-xl-bold'>¿Quién envía?</div>
      <ChooseOptionButton :isSelectable='false'>
        <template v-slot:icon>
          <Icon name='Profile' strokeColor='#FF6568' />
        </template>
        <template v-slot:content>
          <div class='text-m-regular'>{{ order.origin.contactName }}</div>
        </template>
      </ChooseOptionButton>
    </div>
    <!-- Dirección de recojo -->
    <div class='order_detailed_container--specific_information'>
      <div class='text-xl-bold'>Dirección de recojo</div>
      <ChooseOptionButton :isSelectable='false'>
        <template v-slot:icon>
          <Icon name='Pin13' strokeColor='#FF6568' />
        </template>
        <template v-slot:content>
          <div class='text-m-regular'>{{ order.origin.address }}</div>
        </template>
      </ChooseOptionButton>
    </div>
    <!-- Paquete -->
    <div class='order_detailed_container--specific_information'>
      <div class='text-xl-bold'>Paquete</div>
      <PackageSummary
        :includeEditButton='false'
        :includeDeleteButton='false'
        :packageData='packageSummaryData'
      />
    </div>
    <!-- Tipo de comprobante -->
    <div class='order_detailed_container--specific_information' v-if="order.state != 'PDP' && order.state != 'CFP'">
      <div class='text-xl-bold'>Tipo de comprobante</div>
      <ChooseOptionButton :isSelectable='order.invoiceNumber' @click='downloadInvoice'>
        <template v-slot:icon>
          <Icon name='Receipt-bill' strokeColor='#FF6568' />
        </template>
        <template v-slot:content>
          <div class='text-m-regular'>{{ order.typeVoucher === 'FAC' ? 'Factura' : 'Boleta' }}</div>
        </template>
        <template v-slot:right-icon>
          <div v-if='order.invoiceNumber'>
            <Icon name='Down-Line' />
          </div>
          <div v-else>
            <div class='text-s-regular'>PENDIENTE</div>
          </div>
        </template>
      </ChooseOptionButton>
      <div v-show='!order.invoiceNumber' class='text-s-regular' style='color:#737373; margin-top:-10px;' >El comprobante de pago puede tardar hasta 72 horas en ser generado.</div>
    </div>
    <!-- Método de pago -->
    <div class='order_detailed_container--specific_information' v-if='this.order.cardNumber'>
      <div class='text-xl-bold'>Método de pago</div>
      <ChooseOptionButton :isSelectable='false'>
        <template v-slot:icon>
          <Icon name='BankCardDouble' strokeColor='#FF6568' />
        </template>
        <template v-slot:content>
          <div class='text-m-regular'>{{ order.cardBrand }} {{ order.cardNumber }}</div>
        </template>
      </ChooseOptionButton>
    </div>
    <!-- ¿Tienes un cupón de descuento? -->
    <div class='order_detailed_container--specific_information'>
      <div class='text-xl-bold'>¿Tienes un cupón de descuento?</div>
      <ChooseOptionButton :isSelectable='false'>
        <template v-slot:icon>
          <Icon name='Discount-ticket' strokeColor='#FF6568' />
        </template>
        <template v-slot:content>
          <div class='text-m-regular'>{{ order.coupon || 'Sin cupón' }}</div>
        </template>
      </ChooseOptionButton>
    </div>
    <!-- Descargar guía individual -->
    <div class='order_detailed_container--specific_information' v-if="order.state != 'PDP' && order.state != 'CFP'">
      <div class='text-xl-bold'>Guía individual</div>
      <ChooseOptionButton @click='downloadFile'>
        <template v-slot:icon>
          <Icon name='Down-Line' strokeColor='#FF6568' />
        </template>
        <template v-slot:content>
          <div class='text-m-regular'>Descargar</div>
        </template>
        <template v-slot:right-icon>
          <div></div>
        </template>
      </ChooseOptionButton>
    </div>
    <!-- Descargar guía masiva -->
    <div class='order_detailed_container--specific_information' v-if="order.state != 'PDP' && order.state != 'CFP'">
      <div class='text-xl-bold'>Guía masiva</div>
      <ChooseOptionButton @click='downloadFileMassive'>
        <template v-slot:icon>
          <Icon name='Down-Line' strokeColor='#FF6568' />
        </template>
        <template v-slot:content>
          <div class='text-m-regular'>Descargar</div>
        </template>
        <template v-slot:right-icon>
          <div></div>
        </template>
      </ChooseOptionButton>
    </div>

  </div>
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'
import Icon from '@/components/Icon.vue'
import ChooseOptionButton from '@/components/Button/ChooseOptionButton.vue'
import PackageSummary from '@/components/PackageSummary.vue'
import { downloadGuide, downloadInvoice } from "../../services/guias";

export default {
  components: {
    ButtonDefinitive,
    Icon,
    ChooseOptionButton,
    PackageSummary,
  },
  data() {
    return {
      order: {
        code: '',
        date: '',
        serviceType: '',
        state: '',
        stateDescription: '',
        totalFare: '',
        pagoEfectivoCip: '',
        totalOrderFare: '',
        pagoEfectvioExpirationDate: '',

        origin: {
          contactName: '',
          address: '',
        },

        typeVoucher: '',
        invoiceNumber: '',
        cardBrand: '',
        cardNumber: '',
        coupon: '',
      },

      packageSummaryData: {
        destinationAddress: {
          contact: {
            name: ''
          },
          puntoScharffId: {
            Name: ''
          },
          description: '',
          districtId: {
            text: ''
          }
        },
        description: '',
        packageSize: {
          width: '',
          height: '',
          lengths: '',
          weight: '',
        },
        productValue: '',
        secondAttemptInPS: '',
        fare: ''
      },

      textCopyTracking: 'Copiar enlace',

      textCopyCIP: 'Copiar'
    }
  },
  methods: {
    async downloadFile() {
      try {
        this.$swal.showLoading()
        await downloadGuide(this.order.id, this.order.code, false)
        this.$swal.close()
      } catch (error) {
        this.showError("Error al descargar la guia")
        console.error(error)
      }
    },
    async downloadFileMassive() {
      try {
        this.$swal.showLoading()
        await downloadGuide(this.order.id, this.order.code, true)
        this.$swal.close()
        this.trackEvent('click_descargar_guia_masiva', 'mispedidos')
      } catch (error) {
        this.showError("Error al descargar la guia")
        console.error(error)
        this.trackEvent('click_descargar_guia_masiva_error', 'mispedidos')
      }
    },
    initializeData() {
      const currentOrder = this.$store.getters.HISTORY_ORDER_DETAIL
      console.log(JSON.parse(JSON.stringify(currentOrder)))
      this.order.id = this.$store.getters.HISTORY_ORDER_DETAIL_ID
      this.order.code = currentOrder.code
      this.order.date = currentOrder.date
      this.order.serviceType = currentOrder.serviceType
      this.order.state = currentOrder.state
      this.order.stateDescription = currentOrder.stateClient

      this.order.pagoEfectivoCip = currentOrder.pagoEfectivoCip
      this.order.totalOrderFare = currentOrder.totalVoucherFare
      this.order.pagoEfectvioExpirationDate = currentOrder.pagoEfectivoExpDate

      this.order.totalFare = currentOrder.totalFare

      this.order.origin.contactName = currentOrder.points[0].contactName
      this.order.origin.pointName = currentOrder.points[0].scharffPointName
      this.order.origin.address = `${currentOrder.points[0].address} | ${currentOrder.points[0].scharffPointName}`

      this.packageSummaryData = {
        destinationAddress: {
          contact: {
            name: currentOrder.points[1].contactName
          },
          puntoScharffId: {
            Name: currentOrder.points[0].scharffPointName
          },
          description: currentOrder.points[0].address,
          districtId: {
            text: `${currentOrder.points[0].departamentoDesc} - ${currentOrder.points[0].provinciaDesc} - ${currentOrder.points[0].distritoDesc}`
          }
        },
        description: currentOrder.packageDescription,
        packageSize: {
          width: currentOrder.packageWidth,
          height: currentOrder.packageHeight,
          lengths: currentOrder.packageDepth,
          weight: currentOrder.packageWeight,
        },
        productValue: currentOrder.packageValue,
        secondAttemptInPS: currentOrder.useScharffPointForSecondAttempt,
        fare: currentOrder.totalFare
      }

      this.order.typeVoucher = currentOrder.billVoucherType
      this.order.invoiceNumber = currentOrder.invoiceNumber

      this.order.cardBrand = currentOrder.cardBrand
      this.order.cardNumber = currentOrder.cardNumber;

      this.order.coupon = currentOrder.coupon

      // this.order.packageValue = currentOrder.packageValue;
      // this.order.categories = currentOrder.categories;
      // this.order.pagoEfectivoPdfUrl = currentOrder.pagoEfectivoPdfUrl;
      // this.order.pagoEfectivoWebUrl = currentOrder.pagoEfectivoWebUrl;

      // this.order.currency = "S/";
      // this.order.origin.address = currentOrder.points[0].address;
      // this.order.origin.contact.phone = currentOrder.points[0].contactPhoneNumber;
      // this.order.origin.contact.name = currentOrder.points[0].contactName;
      // this.order.destination.addresses = currentOrder.points.filter(
      //   (value, index) => index !== 0
      // );
    },
    sendToTracking() {
      window.open(process.env.VUE_APP_TRACKING_URL + `?code=${this.order.code}`, '_blank')
    },
    copyTracking() {
      this.textCopyTracking = '¡Copiado!'
      navigator.clipboard.writeText(process.env.VUE_APP_TRACKING_URL + `?code=${this.order.code}`)
      .then(() => {
        this.trackEvent('click_copiar_cip')
        setTimeout(() => this.textCopyTracking = 'Copiar enlace', 1000)
      })
      .catch((error) => {
        console.error('Could not copy text: ', error)
        this.trackEvent('click_copiar_cip_error')
      })
    },
    copyText() {
      navigator.clipboard.writeText(this.order.pagoEfectivoCip)
      .then(_ => {
        this.textCopyCIP = '¡Copiado!'
        setTimeout(() => this.textCopyCIP = 'Copiar', 1000)
        this.trackEvent('click_copiar_cip')
      })
      .catch(_ => {
        this.trackEvent('click_copiar_cip_error')
      })
    },
    async downloadInvoice() {
      if (!this.order.invoiceNumber) return
      try {
        this.$swal.showLoading();
        await downloadInvoice(this.order.code, this.order.invoiceNumber, false);
        this.$swal.close();
        this.trackEvent('click_descargar_guia_individual', 'mispedidos')
      } catch (error) {
        this.showError("Error al descargar la guia");
        console.error(error);
        this.trackEvent('click_descargar_guia_individual_error', 'mispedidos')
      }
    },
  },
  async mounted() {
    this.initializeData()
  },
}
</script>

<style lang='scss' scoped>
.order_detailed_container {
  padding-top: 70px !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 50px;
  height: 2000px;
  max-height: 90%;
  overflow-y: auto;
  display: flex; flex-direction: column;
  gap: 48px;

  .paid {
    max-height: 120% !important
  }

  &--initial_data_and_buttons {
    &--initial_data {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;
  
      border-radius: 16px;
      border: 1px solid var(--color-border-tertiary, #D0D0D0);
  
      &--frame {
        width: 100%;
        display: flex; justify-content: space-between;
      }
    }
    &--tracking_buttons {
      display: flex;
      flex-direction: row;
      padding-top: 16px;
      gap: 8px;
    }
  }

  &--specific_information {
    display: flex; flex-direction: column;
    gap: 16px;
  }

  &--amount_to_paid {
    display: flex; flex-direction: column;
    gap: 12px;

    &--card_payment_content {
      background: #FFF;
      border: 1px solid #D0D0D0;
      border-radius: 12px;
      padding: 16px;
    }
  }

  &--amount_paid {
    display: flex;
    padding: 8px 12px;
    gap: 8px;

    border-radius: 12px;
    background: var(--verde-verde-palido, #99EBDD);
    width: fit-content;
  }


  &::-webkit-scrollbar { width: 15px; }
  &::-webkit-scrollbar-thumb {
    background: rgba(26, 26, 26, 0.5);
    background-clip: content-box;
    border: 5px solid transparent;
    border-radius: 15px;
  }
}

</style>